import React, { useState } from 'react';

const Attachments = ({ attachments }) => {
  return (
    <div dir="rtl" className="bg-white md:w-1/2 lg:w-1/2 mt-4">
      <span className="grid grid-flow-row-dense md:grid-cols-3 grid-cols-1 md:grid-rows-3 grid-rows-1 ">
        {attachments?.map((attachment, index) => {
          return (
            <div key={index} className="mx-8 ">
              <a
                href={attachment.url}
                target="_blank"
                rel="noopener noreferrer">
                <img
                  src={attachment.url}
                  alt={attachment.description}
                  className="rounded-lg shadow-lg mb-4 boreder-2 border-gray-300 thumbnail"
                />
                {attachment.description && (
                  <span className="">
                    {index + 1} - {attachment.description}
                  </span>
                )}
              </a>
            </div>
          );
        })}
      </span>
    </div>
  );
};

export default Attachments;
