import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../../api/api';
import { useQuery } from 'react-query';
import ClassSessions from './sessions';

const ShapeUp = () => {
  const { state } = useLocation();
  const [userProducts, setUserProducts] = useState();

  const getvideos = async () => {
    const data = await api.get('/sessionvideo', {
      params: {
        product: state.data,
      },
    });

    return data;
  };
  const { data: posts, error, isLoading } = useQuery('postsData', getvideos);
  console.log('posts', posts);
  useEffect(() => {
    const credentials = localStorage.getItem('credentials');
    const parsedCredentials = JSON.parse(credentials);
    setUserProducts(parsedCredentials?.userProduct);
    getvideos();
  }, []);

  if (isLoading) return <div>Fetching posts...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;
  return <ClassSessions data={posts} />;
};

export default ShapeUp;
