import React, { useState } from 'react';

const AdditionalLinks = ({ additionalLinks }) => {
  console.log(additionalLinks);
  return (
    <div dir="rtl" className=" bg-white  m-4">
      <span className="  ">
        {additionalLinks.map((additionalLinks, index) => {
          return (
            <div key={index} className=" ">
              <a
                href={additionalLinks.link}
                target="_blank"
                rel="noopener noreferrer">
                {additionalLinks.name && (
                  <>
                    {additionalLinks?.logo && (
                      <img
                        src={additionalLinks?.logo}
                        alt={additionalLinks.name}
                        className="rounded-lg shadow-lg mb-4 boreder-2 border-gray-300 w-20 h-20"
                      />
                    )}
                    <span className="">
                      {additionalLinks.name} {additionalLinks?.os}
                    </span>
                  </>
                )}
              </a>
            </div>
          );
        })}
      </span>
    </div>
  );
};

export default AdditionalLinks;
