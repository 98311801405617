import React from 'react';
import persianNumbers from '../../api/persianNumber';

function ProductCard({ name, price1, price2 }) {
  return (
    <div class="max-w-7xl mx-auto  px-4 sm:px-6 lg:py-24 lg:px-8">
      <h2 class="text-xl font-extrabold tracking-tight text-gray-900 sm:text-2xl">
        نام دوره: {name}
      </h2>
      <div class="grid grid-cols-1 gap-2 sm:grid-cols-2 mt-4">
        <div class="bg-white overflow-hidden shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <dl>
              <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                قیمت برای کاربران خارج از کشور:
              </dt>
              <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600">
                {price1} €
              </dd>
            </dl>
          </div>
        </div>
        <div class="bg-white overflow-hidden shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <dl>
              <dt class="text-sm leading-5 font-medium text-gray-500 truncate">
                قیمت برای کاربران داخل کشور:
              </dt>
              <dd class="mt-1 text-3xl leading-9 font-semibold text-indigo-600">
                {persianNumbers(price2)} تومان
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductCard;
