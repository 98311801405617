import React, { useState, useEffect } from 'react';
import api from '../../api/api';

const SessionQuiz = ({ sessionId, userId }) => {
  const [quiz, setQuiz] = useState([]);
  console.log('quiz', quiz, sessionId, userId);
  const [answers, setAnswers] = useState([]);
  const [attemptsLeft, setAttemptsLeft] = useState(2);
  const [score, setScore] = useState(null);
  const [message, setMessage] = useState('');

  // Fetch quiz questions
  useEffect(() => {
    const fetchQuiz = async () => {
      try {
        const response = await api.get(`/quiz/${sessionId}`, {
          params: { userId },
        });
        setQuiz(response.data.quiz);
        setAttemptsLeft(response.data.attemptsLeft);
      } catch (error) {
        console.error('Error fetching quiz:', error);
      }
    };
    fetchQuiz();
  }, [sessionId]);

  // Handle answer selection
  const handleAnswerChange = (questionIndex, selectedOption) => {
    const updatedAnswers = [...answers];
    updatedAnswers[questionIndex] = selectedOption;
    setAnswers(updatedAnswers);
  };

  // Submit answers
  const handleSubmit = async () => {
    try {
      const response = await api.post('/quiz/submit', {
        userId,
        sessionId,
        answers,
      });
      setScore(response.data.score);
      setAttemptsLeft(response.data.attemptsLeft);
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error submitting quiz:', error);
    }
  };

  return (
    <div dir="rtl" className="mx-5 my-2 ">
      {message && <div>{message}</div>}
      {score !== null && <div>امتیاز شما: {score}</div>}
      <span>
        برای برخورداری از هدیه، می بایست حداقل ۹۰٪ سوالات را درست پاسخ دهید. بعد
        از مشاهده هر جلسه، ۴ سوال ۴ گزینه ای از شما پرسیده میشود. شما دو بار
        فرصت دارید که تست هر جلسه را انجام دهید
      </span>
      {quiz.length != 0 && (
        <div
          className="bg-warning border border-main rounded p-2 my-3"
          dir="rtl">
          شما {attemptsLeft}بار دیگر میتوانید تلاش کنید{' '}
        </div>
      )}
      {quiz.map((q, index) => (
        <div className="border rounded p-5 my-2" key={index}>
          <p className="font-bold text-xl mx-2 mb-3 border-b">{q.question}</p>
          <div className="flex flex-col ">
            {q.options.map((option, optionIndex) => (
              <label key={optionIndex}>
                <input
                  className="mx-2"
                  type="radio"
                  name={`question-${index}`}
                  value={optionIndex}
                  onChange={() => handleAnswerChange(index, optionIndex)}
                />
                {option}
              </label>
            ))}
          </div>
        </div>
      ))}
      {quiz.length != 0 && (
        <button
          className="btn bg-success text-white"
          onClick={handleSubmit}
          disabled={attemptsLeft === 0}>
          ثبت پاسخ
        </button>
      )}
    </div>
  );
};

export default SessionQuiz;
