import React from 'react';

function Prices() {
  const stats = [
    {
      id: 1,
      name: 'قیمت برای کاربران خارج از ایران',
      value: '150',
      //RealValue: '125 € ',
      currency: '€',
    },
    {
      id: 2,
      name: 'قیمت برای کاربران داخل ایران',
      value: '۶،۰۰۰،۰۰۰',
      // RealValue: '۴،۵۰۰،۰۰۰ تومان',
      currency: 'تومان',
    },
  ];

  return (
    <section className="text-gray-600 body-font">
      <div className="text-main  lg:px-8 lg:py-20 bg-main text-white">
        <div className="grid gap-10 row-gap-8 lg:grid-cols-2 px-3 py-4">
          {stats.map((stat) => (
            <div className="flex flex-col items-center p-4 bg-blue-600 rounded-lg shadow-lg">
              <div className="flex">
                <h6 className="mr-2 text-4xl font-bold md:text-5xl text-deep-purple-accent-400 text-center">
                  {stat.value} {stat.currency}
                </h6>
                {/* <div className="flex items-center justify-center rounded-full text-white w-30 h-10">
                  <p
                    className="text-white font-custom1 line-through text-3xl"
                    dangerouslySetInnerHTML={{ __html: stat.RealValue }}></p>
                </div> */}
              </div>

              <p
                className="text-white font-custom1"
                dangerouslySetInnerHTML={{ __html: stat.name }}></p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Prices;
