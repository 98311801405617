import React from 'react';
import { Link } from 'react-router-dom';

function Prices({ data }) {
  const stats = [
    {
      id: 1,
      name: ' برای کاربران خارج از ایران',
      //value: '45',

      RealValue: '۷۰ € ',
      // realValueDesc: 'ارزش واقعی دوره',
      //currency: '€',
    },
    {
      id: 2,
      name: 'برای کاربران داخل ایران',
      //value: '۹۵۰،۰۰۰',
      RealValue: '۳،۴۹۹،۰۰۰ تومان',
      // realValueDesc: 'ارزش واقعی دوره',
      // discountDesc: 'فقط تا آخر اسفند ماه',
      //currency: 'تومان',
    },
  ];

  return (
    <section className="text-gray-600 body-font bg-transparent">
      <div className="text-main flex flex-col lg:px-2 lg:py-2 bg-lighter text-white mx-2 rounded	">
        <div className="flex flex-col">
          {stats.map((stat) => (
            <div
              className="flex flex-col items-center p-4 my-1 justify-between border-b-2 border-gray-200
            ">
              <div className="row-span-1">
                <span className="text-main  text-sm md:text-base">
                  {stat?.realValueDesc}
                  <span className="text-main font-bold text-xl md:text-base">
                    {stat?.RealValue}
                  </span>
                </span>

                <span className="text-red-500 text-sm md:text-base">
                  {' '}
                  {stat?.discountDesc}
                </span>
              </div>
              <div className="row-span-2">
                <h6 className="mr-2 text-xl font-bold md:text-3xl text-main text-right">
                  <span className="text-main text-sm md:text-md">
                    {stat.name}
                  </span>{' '}
                  {stat.value} {stat.currency}
                </h6>
              </div>
            </div>
          ))}
        </div>
        <Link
          style={{
            textDecoration: 'none',
            margin: '0 10px',
          }}
          to={`/userlogin`}
          state={{ data: data }}>
          <button
            className="

                  bg-gold hover:bg-silver text-main font-bold hover:text-main py-2 px-2 border border-blue-500 hover:border-transparent rounded w-full my-2 ">
            ثبت نام
          </button>
        </Link>
      </div>
    </section>
  );
}

export default Prices;
