import moment from 'moment-jalaali';
import React from 'react';

function CoachReport({
  coachReport,
  proteinAmount,
  fatAmount,
  carbsAmount,
  maintainCalorie,
  allCoachReport,
}) {
  return (
    <div className="w-full mb-5">
      {/* Main Report Section */}
      <div className="collapse collapse-plus bg-base-200 mb-5">
        <input type="checkbox" id="main-report" />
        <label
          className="collapse-title text-xl font-medium"
          htmlFor="main-report">
          اهداف هفته آینده
        </label>
        <div className="collapse-content">
          <div className="mb-8">
            <div className="text-gray-900 font-bold text-xl mb-2">
              اهداف هفته آینده
            </div>
            <div className="text-sm">
              <span
                className={`${
                  coachReport?.rate === 1
                    ? 'bg-yellow-200'
                    : coachReport?.rate === 2
                    ? 'bg-sky-500'
                    : 'bg-orange-600'
                } text-blue-800 text-xs font-medium px-2.5 py-0.5 rounded`}>
                {coachReport?.rate === 1
                  ? 'عالی'
                  : coachReport?.rate === 2
                  ? 'خوب'
                  : 'متوسط'}
              </span>
            </div>
            <div className="flex flex-col lg:flex-row justify-between mt-5">
              <span className="text-sm mb-5 p-6 bg-white border rounded-lg shadow lg:w-1/2">
                {coachReport.next_Goal}
              </span>
              <div className="max-w-sm lg:w-1/2 p-6 bg-white border rounded-lg shadow">
                <ul className="text-right text-gray-700">
                  <li>
                    <span>کالری تثبیت:</span> {maintainCalorie} کیلوکالری
                  </li>
                  <hr />
                  <li>
                    <span>کالری:</span> {coachReport?.calories} کیلوکالری
                  </li>
                  <hr />
                  <li>
                    <span>پروتیین:</span> {coachReport?.protein}%{' '}
                    {proteinAmount !== 0 && <span>({proteinAmount} گرم)</span>}
                  </li>
                  <hr />
                  <li>
                    <span>کربوهیدرات:</span> {coachReport?.carbs}%{' '}
                    {carbsAmount !== 0 && <span>({carbsAmount} گرم)</span>}
                  </li>
                  <hr />
                  <li>
                    <span>چربی:</span> {coachReport?.fat}%{' '}
                    {fatAmount !== 0 && <span>({fatAmount} گرم)</span>}
                  </li>
                  <hr />
                  <li>
                    <span>فیبر:</span> {coachReport?.fiber} گرم
                  </li>
                  <hr />
                  <li>
                    <span>میانگین قدمهای روزانه:</span> {coachReport?.steps} قدم
                    در روز
                  </li>
                  <hr />
                  <li>
                    <span>ورزش قدرتی:</span> {coachReport?.exercise} روز در هفته
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Past Reports */}
      {allCoachReport.map((item, i) => (
        <div key={i} className="collapse collapse-plus bg-base-200 mb-3">
          <input type="checkbox" id={`report-${i}`} />
          <label
            className="collapse-title text-xl font-medium"
            htmlFor={`report-${i}`}>
            گزارش{' '}
            {new Intl.DateTimeFormat('fa-IR', {
              dateStyle: 'short',
            }).format(new Date(item?.response_date))}
          </label>
          <div className="collapse-content">
            <p>{item?.next_Goal || 'No details provided'}</p>
            <ul className="text-right text-gray-700">
              <li>
                <span>کالری تثبیت:</span> {item?.maintainCalorie} کیلوکالری
              </li>
              <hr />
              <li>
                <span>کالری:</span> {item?.calories} کیلوکالری
              </li>
              <hr />
              <li>
                <span>پروتیین:</span> {item?.protein}%{' '}
              </li>
              <hr />
              <li>
                <span>کربوهیدرات:</span> {item?.carbs}%{' '}
              </li>
              <hr />
              <li>
                <span>چربی:</span> {item?.fat}%{' '}
              </li>
              <hr />
              <li>
                <span>فیبر:</span> {item?.fiber} گرم
              </li>
              <hr />
              <li>
                <span>میانگین قدمهای روزانه:</span> {item?.steps} قدم در روز
              </li>
              <hr />
              <li>
                <span>ورزش قدرتی:</span> {item?.exercise} روز در هفته
              </li>
            </ul>
          </div>
        </div>
      ))}
    </div>
  );
}

export default CoachReport;
