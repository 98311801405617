import { Button, Card } from 'flowbite-react';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CommentForm from '../../../../component/comment';
import Attachments from '../../../../component/attachments';
import ViewCounter from '../../../../component/counter';
import AdditionalLinks from '../../../../component/additionalLinks';
import './style.css';
import SessionQuiz from '../../../../component/quiz';

const ClassSession = () => {
  const { state } = useLocation();

  const {
    video,
    link,
    attachments,
    _id,
    product,
    session,
    description,
    additionalLinks,
  } = state.data;

  console.log('state', state.data);
  const userId = localStorage.getItem('userId');

  return (
    <>
      <button
        className="mx-4 font-bold cursor-pointer bg-main rounded p-2 text-white"
        onClick={() => (window.location.href = '/dashboard')}>
        {`  < برگشت به صفحه اصلی`}
      </button>
      <div dir="ltr" className="max-w-full bg-white">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-2 items-center lg:mx-9">
          <div className=" mt-4 ">
            {video ? (
              <video
                src={video}
                controls
                className="w-full lg:h-96 object-cover"
              />
            ) : (
              <iframe
                className="w-full lg:h-96"
                src={link}
                allow="autoplay"></iframe>
            )}
          </div>

          <div
            dir="rtl"
            className="rounded-lg p-4 h-auto border-gray-300 m-auto">
            <h4 className="text-2xl font-bold">توضیحات جلسه</h4>
            <p className="text-gray-700 p-4 dark:text-gray-400">
              {description}
            </p>
          </div>
        </div>

        <div dir="rtl" className="rounded-lg p-4 border-gray-300 mt-3">
          <div className="flex flex-col mx-5">
            <div
              tabIndex={0}
              className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box">
              <input type="checkbox" />
              <div className="collapse-title text-xl font-medium bg-main text-white">
                پیوست ها
              </div>
              <div className="collapse-content">
                <Attachments attachments={attachments} />
              </div>
            </div>

            {additionalLinks?.length > 0 && (
              <div
                tabIndex={0}
                className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box mt-4">
                <input type="checkbox" />
                <div className="collapse-title text-xl font-medium bg-main text-white">
                  لینک ها
                </div>
                <div className="collapse-content">
                  <AdditionalLinks additionalLinks={additionalLinks} />
                </div>
              </div>
            )}

            {state.data.product === 'Shape up Academy' && (
              <div
                tabIndex={0}
                className="collapse collapse-arrow border border-base-300 bg-base-100 rounded-box mt-4">
                <input type="checkbox" />
                <div className="collapse-title text-xl font-medium bg-main text-white">
                  آزمون جلسه
                </div>
                <div className="collapse-content">
                  <SessionQuiz sessionId={_id} userId={userId} />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-row">
          <CommentForm id={_id} product={product} session={session} />
        </div>
      </div>
    </>
  );
};

export default ClassSession;
