import React from 'react';
import { Link } from 'react-router-dom';
import ActiveWorkshop from './ActiveWorkshop';
import NotActiveWorkshop from './NotActiveWorkshop';
import BeforAfters from './BeforAfters';

const sessions = [
  {
    id: 1,
    name: 'Shape up with Azar',
    status: 'deactive',
    active: false,
  },
  {
    id: 2,
    name: 'Shape up pro',
    status: 'deactive',
    active: false,
  },
  {
    id: 3,
    name: 'Shape up 2',
    telegram: 'https://t.me/+DHG3M8CsQOFlMjg0',
    zoom: 'https://us06web.zoom.us/j/81506905687?pwd=VEdxSlJIVEZnaVVmanhHeE1kNkdEZz09',
    status: 'deactive',
    active: false,
  },
  {
    id: 4,
    name: 'Shape up 3',
    telegram: 'https://t.me/+Xnq_hivnP0BmNjY0',
    zoom: '',
    status: 'deactive',
    active: false,
  },
  {
    id: 5,
    name: 'Shape up pro 2',
    telegram: 'https://t.me/+DHG3M8CsQOFlMjg0',
    zoom: 'https://meet.google.com/azi-rmer-sis',
    status: 'deactive',
    active: false,
  },
  {
    id: 6,
    name: 'Shape up Academy',
    status: 'active',
    offline: true,
    active: true,
  },
  {
    id: 7,
    name: 'Shape up pro 3',
    telegram: 'https://t.me/+DHG3M8CsQOFlMjg0',
    zoom: 'https://meet.google.com/fgg-uzfs-dos',
    status: 'deactive',
    active: false,
  },
  {
    id: 8,
    // desc: 'شروع دوره از ۲۲ فروردین ۱۴۰۳ هر چهارشنبه ساعت ۸ شب بوقت تهران',
    name: 'Shape up 4',
    telegram: 'https://t.me/+6oHMFATMqqFkMGI0',
    zoom: 'https://meet.google.com/ptn-knjz-ugz',
    status: 'deactive',
    active: false,
  },
  {
    id: 9,
    // desc: 'شروع دوره از ۲۲ فروردین ۱۴۰۳ هر چهارشنبه ساعت ۸ شب بوقت تهران',
    name: 'Shape up pro 4',
    telegram: 'https://t.me/+6oHMFATMqqFkMGI0',
    //  zoom: 'https://meet.google.com/ptn-knjz-ugz',
    status: 'deactive',
    active: false,
  },
  {
    id: 10,
    // desc: ' شروع دوره  از ۲۶ خرداد  ۱۴۰۳  ساعت ۱۹ بوقت تهران ',
    name: 'Shape up 5',
    telegram: 'https://t.me/+6oHMFATMqqFkMGI0',
    //  zoom: 'https://meet.google.com/frr-vegu-qpc',
    status: 'deactive',
    active: false,
  },
  {
    id: 11,
    //desc: ' شروع دوره  از ۲ مهرماه  ۱۴۰۳  ساعت ۲۰ بوقت تهران ',
    name: 'Shape up 6',
    telegram: 'https://t.me/+6oHMFATMqqFkMGI0',
    zoom: 'https://meet.google.com/ojs-xaum-dgq',
    status: 'active',
    active: true,
  },
  {
    id: 12,
    //desc: ' شروع دوره  از چهارشنبه ۷ آذرماه  ۱۴۰۳  ساعت ۲۰ بوقت تهران ',
    name: 'Shape up 7',
    telegram: 'https://t.me/+6oHMFATMqqFkMGI0',
    zoom: 'https://meet.google.com/bzi-icxo-qmw',
    status: 'active',
    active: true,
  },
  {
    id: 13,
    //desc: ' شروع دوره  از ۲۷ شهریور  ۱۴۰۳  ساعت ۲۰ بوقت تهران ',
    name: 'Shape up pro 5',
    telegram: 'https://t.me/+6oHMFATMqqFkMGI0',
    zoom: 'https://meet.google.com/frr-vegu-qpc',
    status: 'active',
    active: true,
  },

  {
    id: 14,
    //desc: ' شروع دوره  از ۲۷ شهریور  ۱۴۰۳  ساعت ۲۰ بوقت تهران ',
    name: 'Shape up pro 6',
    telegram: 'https://t.me/+6oHMFATMqqFkMGI0',
    //zoom: 'https://meet.google.com/frr-vegu-qpc',
    status: 'active',
    active: true,
  },
  {
    id: 15,
    //desc: ' شروع دوره  از ۲۷ شهریور  ۱۴۰۳  ساعت ۲۰ بوقت تهران ',
    name: 'Shape up 8',
    telegram: 'https://t.me/+6oHMFATMqqFkMGI0',
    //zoom: 'https://meet.google.com/frr-vegu-qpc',
    status: 'notstarted',
    active: true,
  },
];

//check if userProduct match with sessions

const ProductData = (props) => {
  const { userProducts } = props;
  const matchedSessions = sessions.filter((session) =>
    userProducts.some((product) => product.name === session.name)
  );

  return (
    <>
      {userProducts ? (
        <>
          <ActiveWorkshop matchedSessions={matchedSessions} />
          <div className="divider"></div>
          <NotActiveWorkshop matchedSessions={matchedSessions} />
          <div className="divider"></div>
          <BeforAfters />
        </>
      ) : (
        <>
          <div className="badge badge-error mx-auto text-white">
            شما هیچ دوره ای ندارید
          </div>
        </>
      )}
    </>
  );
};

export default ProductData;
