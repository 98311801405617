import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function ActiveWorkshop({ matchedSessions }) {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`collapse collapse-arrow bg-base-200 ${isOpen ? 'open' : ''}`}>
      {/* <input type="checkbox" checked={isOpen} onChange={handleToggle} /> */}
      <div
        onClick={handleToggle}
        className="collapse-title text-xl font-medium">
        نمایش دوره های فعال
      </div>
      <div className="my-5 grid md:grid-cols-2 gap-4">
        {matchedSessions
          ?.filter((item) => item.active === true)
          .map((item, i) => (
            <div
              key={i}
              className="cursor-grab flex flex-col items-center justify-center bg-white border border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700
                ">
              <div className="card-body ">
                <h2 className="card-title"> {item.name}</h2>
                {item.status === 'notstarted' && (
                  <div className="badge badge-primary mx-auto">
                    دوره شما هنوز شروع نشده است
                  </div>
                )}
                {item.status === 'active' && (
                  <div className="badge badge-success mx-auto text-white">
                    این دوره برای شما فعال است{' '}
                  </div>
                )}
                {item.desc && (
                  <div className="badge badge-success mx-auto text-white ">
                    {item.desc}
                  </div>
                )}
                {item.status === 'deactive' && (
                  <div className="badge badge-ghost mx-auto">
                    این دوره به پایان رسیده است
                  </div>
                )}

                <div
                  className="card-actions justify-end

                ">
                  <div
                    className={`grid ${
                      item.offline ? 'md:grid-cols-1' : 'md:grid-cols-2'
                    } gap-3 items-center px-3 py-2 text-sm font-medium text-center text-main rounded-lg focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`}>
                    {!item.offline && (
                      <>
                        {item.telegram && (
                          <Link
                            className={
                              item.status === 'notstarted'
                                ? 'bg-gray-500  hover:bg-gray-700 text-white font-bold py-2 px-4 border border-gray-700 rounded'
                                : 'bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'
                            }
                            to={
                              item.status === 'active'
                                ? item.status === 'deactive'
                                  ? null
                                  : item?.telegram
                                : null
                            }>
                            دسترسی به گروه تلگرام
                          </Link>
                        )}
                        <Link
                          disabled={item.status}
                          className={
                            item.status === 'notstarted' ||
                            item.active === false
                              ? 'bg-gray-500 cursor-text text-white font-bold py-2 px-4 border border-gray-700 rounded'
                              : 'bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'
                          }
                          to={item.status === 'active' ? item?.zoom : null}>
                          دسترسی به جلسات آنلاین
                        </Link>
                        <Link
                          disabled={item.status}
                          className={
                            item.status === 'notstarted' ||
                            item.active === false
                              ? 'bg-gray-500  cursor-text text-white font-bold py-2 px-4 border border-gray-700 rounded'
                              : 'bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'
                          }
                          to={
                            item.status === 'active'
                              ? '/dashboard/report'
                              : null
                          }>
                          مشاهده و ارسال گزارش
                        </Link>
                      </>
                    )}
                    <Link
                      className={
                        item?.offline
                          ? 'bg-blue-500 w-full  hover:bg-gray-700 text-white font-bold py-2 px-4 border border-gray-700 rounded'
                          : 'bg-blue-500  hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded'
                      }
                      to={`/dashboard/workshop`}
                      state={{ data: item.name }}>
                      دسترسی به جلسات آفلاین
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ActiveWorkshop;
